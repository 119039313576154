import React, { FC, useState } from 'react';

import PrimaryButton from 'components/PrimaryButton';
import { EnumReceiptStatus, EnumReceiptType } from 'redux/receipt/types';
import Setting from '../../blocks/Setting';

import arrowIcon from 'assets/images/icons/arrowDownBig.png';
import downloadIcon from 'assets/images/icons/download.png';
import repairIcon from 'assets/images/icons/repair.png';
import trashIcon from 'assets/images/icons/trash.png';
import { CircularProgress } from 'components/CircularProgress';
import { Container, Icon, IconBlock, RepairBlock } from './styles';

interface Props {
    active: boolean;
    canExport?: boolean;
    isArchive: boolean;
    receipt_type: EnumReceiptType | undefined;
    onCreate: () => void;
    onDelete: () => void;
    onExport: () => Promise<void>;
    convertReceipt: () => any;
    chnageSelectedFlag: (flag: boolean) => any;
    changeStatusHandle: (status: EnumReceiptStatus) => any;
}

const ActionPanel: FC<Props> = ({
    active,
    canExport = false,
    isArchive,
    receipt_type,
    onCreate,
    onExport,
    onDelete,
    convertReceipt,
    chnageSelectedFlag,
    changeStatusHandle
}) => {
    const [setting, setSetting] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const toogleSetting = () => setSetting(!setting);

    const isActive = (func: () => void) => {
        return () => {
            if (active) {
                func();
            }
        };
    };

    const handleExport = async () => {
        if (canExport && !isExporting) {
            setIsExporting(true);
            await onExport();
            setIsExporting(false);
        }
    };

    return (
        <Container>
            <IconBlock>
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                    <Icon
                        active={canExport && !isExporting}
                        margin="0 25px 0 0"
                        src={downloadIcon}
                        alt="download"
                        onClick={handleExport}
                    />

                    {isExporting && (
                        <CircularProgress
                            style={{
                                position: 'absolute',
                                top: 1,
                                left: 1
                            }}
                            size={10}
                        />
                    )}
                </div>

                <RepairBlock onClick={isActive(toogleSetting)}>
                    <Icon active={active} src={repairIcon} alt="tool" />
                    <Icon active={active} src={arrowIcon} alt="arrow" />
                </RepairBlock>
                <Icon active={active} src={trashIcon} alt="trash" onClick={isActive(onDelete)} />
            </IconBlock>

            <PrimaryButton
                title={'+ ADD'}
                onClick={onCreate}
                style={{ width: '126px', height: '35px' }}
            />

            {setting && (
                <Setting
                    isArchive={isArchive}
                    receipt_type={receipt_type}
                    convertReceipt={convertReceipt}
                    chnageSelectedFlag={chnageSelectedFlag}
                    closePopUp={isActive(toogleSetting)}
                    changeStatusHandle={changeStatusHandle}
                />
            )}
        </Container>
    );
};

export default ActionPanel;
