import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';

import { capitalizeFirstLetter } from 'helpers/other';
import { IGetReceiptParams } from 'net/api';
import { EnumReceiptStatus, EnumReceiptType, IReceipt } from 'redux/receipt/types';
import { ReceiptsProps } from './index';

import DeletePopUp from 'components/DeletePopUp';
import Export from 'components/Export';
import PrimaryHeader from 'components/PrimaryHeader';
import AddReceipt from './blocks/AddReceipt';
import EmailInfo from './blocks/EmailInfo';
import FullPicture from './blocks/FullPicture';
import ActionPanel from './components/ActionPanel';
import SearchPanel from './components/SearchPanel';
import Table from './components/Table';

import { Container } from 'styles/blocks';

const Receipts: FC<ReceiptsProps> = ({
    data,
    user,
    match,
    history,
    loading,
    preview,
    emailInfo,
    duplicateInfo,
    capturedConfig,
    changeFlag,
    getReceipts,
    uploadPhoto,
    loadReceipts,
    changeStatus,
    deleteReceipt,
    convertReceipt,
    getDuplicateInfo,
    toogleCaptureData,
    getReceiptPreview,
    getReceiptEmailInfo,
    restartReceipt,
    exportReceipt,
    exportReceiptsXml
}) => {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            value: ''
        },
        onSubmit: async ({ value }) => {
            getReceiptsWithParams({ value });
        }
    });
    const [deleteLoading, setDeleteLoading] = useState(false);

    const getReceiptsWithParams = ({
        value,
        selected_date,
        startDate,
        endDate,
        duplicate_id,
        company_id,
        by_issue_date
    }: {
        value?: string;
        selected_date?: string;
        startDate?: string;
        endDate?: string;
        duplicate_id?: string;
        company_id?: string;
        by_issue_date?: boolean;
    }) => {
        const params: IGetReceiptParams = {
            receipt_type: receiptType
        };
        if (RECEIPT_STATUS !== 'inbox') {
            params.receipt_status = RECEIPT_STATUS;
        }
        if (value) {
            params.search_value = value;
        }
        if (selected_date) {
            params.selected_date = new Date(new Date(selected_date as any).setHours(15)).getTime();
        }

        if (startDate) {
            params.created_at_after = startDate;
        }

        if (endDate) {
            params.created_at_before = endDate;
        }

        if (duplicate_id) {
            params.duplicate_id = duplicate_id;
        }

        if (company_id) {
            params.company_id = company_id;
        }

        if (by_issue_date) {
            params.by_issue_date = '1';
        }

        getReceipts(params);
    };

    useEffect(() => {
        const RECEIPT_TYPE = match.params.type;
        const RECEIPT_STATUS = match.params.status;
        if (RECEIPT_STATUS === 'inbox') {
            getReceipts({ receipt_type: RECEIPT_TYPE });
        } else {
            getReceipts({
                receipt_type: RECEIPT_TYPE,
                receipt_status: RECEIPT_STATUS
            });
        }
        formik.resetForm();
        setSelected([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.type, match.params.status]);

    const RECEIPT_STATUS = match.params.status;
    const [receiptType, setReceiptType] = useState<EnumReceiptType>();
    //
    const [selected, setSelected] = useState<IReceipt[]>([]);
    const [popUp, setPopUp] = useState({
        export: false,
        setting: false,
        preview: false,
        createReceipt: false,
        deleteReceipt: false,
        capturedData: false,
        emailInfo: false
    });

    const toEdit = (item: IReceipt) => {
        if (item.status === EnumReceiptStatus.Unassigned) {
            history.push({
                pathname: `/select-company/${item.receipt_id}`,
                search: `?company_id=${item.company_id}`,
                state: {
                    inbox: RECEIPT_STATUS === 'inbox'
                }
            });
            return;
        }
        //TODO: add a new field on server editable
        if (
            item.status !== EnumReceiptStatus.Processing &&
            item.status !== EnumReceiptStatus.Converting
        ) {
            history.push({
                pathname: `/edit-receipt/${item.receipt_id}`,
                search: `?company_id=${item.company_id}`,
                state: {
                    inbox: RECEIPT_STATUS === 'inbox'
                }
            });
        }
    };

    const tooglePopUp = (key: string) => {
        return () => {
            setPopUp((prev) => ({
                ...prev,
                // @ts-ignore
                [key]: !prev[key]
            }));
        };
    };

    const openPopUp = (key: string) => {
        return () => {
            setPopUp((prev) => ({
                ...prev,
                // @ts-ignore
                [key]: true
            }));
        };
    };

    const closePopUp = (key: string) => {
        return () => {
            setPopUp((prev) => ({
                ...prev,
                // @ts-ignore
                [key]: false
            }));
        };
    };

    const openFullPicture = (r: IReceipt) => {
        getReceiptPreview(r);
        setPopUp((prev) => ({
            ...prev,
            preview: true
        }));
    };

    const onSelect = (item: IReceipt) => {
        const isSelect = selected.find((r) => r.receipt_id === item.receipt_id);
        if (isSelect) {
            setSelected((prev) => prev.filter((el) => el.receipt_id !== item.receipt_id));
        } else {
            //TODO: add a new field on server editable
            if (
                item.status !== EnumReceiptStatus.Processing &&
                item.status !== EnumReceiptStatus.Converting
            ) {
                setSelected((prev) => prev.concat(item));
            }
        }
    };

    const onSelectAll = () => {
        if (EnumReceiptStatus.Processing === RECEIPT_STATUS) {
            return;
        }

        //TODO: add a new field on server editable
        const notProccess = data.filter(
            (item) =>
                item.status !== EnumReceiptStatus.Processing &&
                item.status !== EnumReceiptStatus.Converting
        );
        if (selected.length !== notProccess.length) {
            setSelected(notProccess);
        } else {
            setSelected([]);
        }
    };

    const chnageSelectedFlag = async (flag: boolean) => {
        for (let i = 0; i < selected.length; i++) {
            await changeFlag(selected[i], flag);
        }
        setSelected([]);
    };

    const deleteReceiptHandle = async () => {
        setDeleteLoading(true);
        for (let i = 0; i < selected.length; i++) {
            await deleteReceipt(selected[i]);
        }
        closePopUp('deleteReceipt')();
        setSelected([]);
        setDeleteLoading(false);
    };

    const onDelete = async (r: IReceipt) => {
        try {
            setDeleteLoading(true);
            await deleteReceipt(r);
        } catch (err) {
        } finally {
            setDeleteLoading(false);
        }
    };

    const onSendAgain = (r: IReceipt) => {
        restartReceipt(r);
    };

    const changeStatusHandle = async (status: EnumReceiptStatus) => {
        for (let i = 0; i < selected.length; i++) {
            await changeStatus(selected[i], status);
        }
        setSelected([]);
    };

    const convertReceiptHandle = async () => {
        for (let i = 0; i < selected.length; i++) {
            await convertReceipt(selected[i]);
        }
        setSelected([]);
    };

    const loadReceiptsHandle = () => {
        if (RECEIPT_STATUS === 'inbox') {
            loadReceipts({
                receipt_type: receiptType
            });
        } else {
            loadReceipts({
                receipt_type: receiptType,
                receipt_status: RECEIPT_STATUS
            });
        }
    };

    const onShowDuplicates = (reset?: boolean) => {
        if (reset) {
            getReceiptsWithParams({});
            return;
        }
        const selected_document = selected[0];

        if (!selected_document) {
            return;
        }

        if (!selected_document.duplicate_id) {
            getReceiptsWithParams({});
            return;
        }

        getReceiptsWithParams({
            duplicate_id: selected_document.duplicate_id,
            company_id: selected_document.company_id
        });
    };

    const onSelectedDateRange = (startDate: string, endDate: string, by_issue_date: boolean) => {
        getReceiptsWithParams({ startDate, endDate, by_issue_date });
    };

    const onClearDate = () => {
        getReceiptsWithParams({});
    };

    const _onScroll = (event: any) => {
        const element = event.target;
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            loadReceiptsHandle();
        }
    };

    return (
        <Container onScroll={_onScroll}>
            <PrimaryHeader
                margin={`0 0 26px 0`}
                title={capitalizeFirstLetter(match.params.status)}
                onClick={() => history.push('/my-profile')}
            />
            <ActionPanel
                isArchive={RECEIPT_STATUS === 'archive'}
                receipt_type={receiptType}
                active={selected.length > 0}
                convertReceipt={convertReceiptHandle}
                // onExport={openPopUp('export')}
                /*onExport={() => {
                    selected.length === 1 && exportReceipt(selected[0]);
                }}*/
                onExport={() =>
                    exportReceiptsXml({
                        receipt_ids: selected.map(({ receipt_id }) => receipt_id)
                    })
                }
                onDelete={openPopUp('deleteReceipt')}
                onCreate={openPopUp('createReceipt')}
                chnageSelectedFlag={chnageSelectedFlag}
                changeStatusHandle={changeStatusHandle}
                //canExport={selected.length === 1}
                canExport={selected.length > 0}
            />
            <SearchPanel
                formik={formik}
                isActiveDuplicates={selected.length === 1}
                onSelectedDateRange={onSelectedDateRange}
                onClearDateRange={onClearDate}
                onShowDuplicates={onShowDuplicates}
                setReceiptType={setReceiptType}
            />
            <Table
                data={data}
                popUp={popUp}
                loading={loading}
                selected={selected}
                receiptType={receiptType}
                duplicateInfo={duplicateInfo}
                capturedConfig={capturedConfig}
                toEdit={toEdit}
                onSelect={onSelect}
                openPopUp={openPopUp}
                closePopUp={closePopUp}
                changeFlag={changeFlag}
                tooglePopUp={tooglePopUp}
                onSelectAll={onSelectAll}
                openFullPicture={openFullPicture}
                getDuplicateInfo={getDuplicateInfo}
                toogleCaptureData={toogleCaptureData}
                getReceiptEmailInfo={getReceiptEmailInfo}
                onDelete={onDelete}
                onSendAgain={onSendAgain}
            />

            {/* PopUps */}
            {popUp.emailInfo && <EmailInfo data={emailInfo} closePopUp={closePopUp('emailInfo')} />}
            {popUp.createReceipt && (
                <AddReceipt
                    user={user}
                    uploadPhoto={uploadPhoto}
                    receipt_type={receiptType}
                    onUserClick={() => history.push('/my-team', { addUser: true })}
                    closePopUp={closePopUp('createReceipt')}
                />
            )}
            {popUp.preview && <FullPicture preview={preview} />}
            {popUp.export && (
                <Export
                    selected={selected}
                    changeStatusHandle={changeStatusHandle}
                    closeModal={closePopUp('export')}
                />
            )}
            {popUp.deleteReceipt && (
                <DeletePopUp
                    loading={deleteLoading}
                    title="Are you sure you want to delete the selected items?"
                    onDelete={deleteReceiptHandle}
                    closePopUp={closePopUp('deleteReceipt')}
                />
            )}
        </Container>
    );
};

export default Receipts;
