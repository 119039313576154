import React from 'react';

interface CircularProgressProps {
    size?: number;
    style?: React.CSSProperties;
}

export const CircularProgress: React.FC<CircularProgressProps> = ({ size = 40, style }) => {
    const progressStyle: React.CSSProperties = {
        width: size,
        height: size,
        borderRadius: '100%',
        backgroundColor: 'transparent',
        animation: 'spin 1.5s linear infinite',
        border: '4px dotted #a665d1'
    };

    return <div style={{ ...progressStyle, ...style }} />;
};

// Add keyframes for spin animation
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(
    `
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`,
    styleSheet.cssRules.length
);

// ... existing code
