import styled from 'styled-components/macro';

export const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    margin-bottom: 44px;
`;

export const IconBlock = styled.div`
    display: flex;
    align-items: center;
`;

interface IconProps {
    active: boolean;
    margin?: string;
}
export const Icon = styled.img`
    width: 18px;
    height: 18px;
    margin: ${({ margin }: IconProps) => (margin ? margin : '0px')};
    cursor: pointer;
    opacity: ${({ active }: IconProps) => (active ? 1 : 0.4)};
`;

export const RepairBlock = styled.div`
    margin-right: 25px;
    display: flex;
    align-items: center;
`;
